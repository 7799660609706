<template>
  <div class="medialist">
       <a-page-header
          :title="$t('inventory.titel')"
          class="header_title"
      />
      <a-spin :spinning="spinning">
      <div class="box_content">
            <table>
              <tr>
                <td>{{$t('inventory.search.condition')}}:</td>
                <td><a-checkbox :checked="condition_0" @change="(e)=>handleChange(e,'condition_0')">{{$t('inventory.search.all')}}</a-checkbox></td>
                <td><a-checkbox :checked="condition_1" @change="(e)=>handleChange(e,'condition_1')">{{$t('inventory.search.pre_owned')}}</a-checkbox></td>
                <td><a-checkbox :checked="condition_2" @change="(e)=>handleChange(e,'condition_2')">{{$t('inventory.search.used')}}</a-checkbox></td>
                <td><a-checkbox :checked="condition_3" @change="(e)=>handleChange(e,'condition_3')">{{$t('inventory.search.brand_new')}}</a-checkbox></td>
                <!-- <td><a-checkbox :checked="condition_1" @change="(e)=>handleChange(e,'condition_1')">Brand New</a-checkbox></td>
                <td><a-checkbox :checked="condition_2" @change="(e)=>handleChange(e,'condition_2')">UN - Unknown</a-checkbox></td>
                <td><a-checkbox :checked="condition_3" @change="(e)=>handleChange(e,'condition_3')">Refurbushed 90P</a-checkbox></td>
                <td><a-checkbox :checked="condition_4" @change="(e)=>handleChange(e,'condition_4')">Refurbushed 80P</a-checkbox></td>
                <td><a-checkbox :checked="condition_5" @change="(e)=>handleChange(e,'condition_5')">Scrap</a-checkbox></td> -->
              </tr>
              <tr>
                <td>{{$t('inventory.search.inventory_status')}}:</td>
                <td><a-checkbox :checked="inventory_status_0" @change="(e)=>handleChange(e,'inventory_status_0')">{{$t('inventory.search.all')}}</a-checkbox></td>
                <td><a-checkbox :checked="inventory_status_1" @change="(e)=>handleChange(e,'inventory_status_1')">{{$t('inventory.search.in_stock')}}</a-checkbox></td>
                <td><a-checkbox :checked="inventory_status_2" @change="(e)=>handleChange(e,'inventory_status_2')">{{$t('inventory.search.in_inspection')}}</a-checkbox></td>
                <td><a-checkbox :checked="inventory_status_3" @change="(e)=>handleChange(e,'inventory_status_3')">{{$t('inventory.search.pending_to_receive')}}</a-checkbox></td>
              </tr>
              <tr>
                <!-- <td>Warehouse:</td>
                <td colspan="2">
                  <a-select style="width: 100%" :value="Warehouse" @change="(e)=>handleChange(e,'Warehouse')">
                      <a-select-option value="0">All</a-select-option>
                      <a-select-option value="1">Gyroor</a-select-option>
                      <a-select-option value="2">PanurgyOEM</a-select-option>
                      <a-select-option value="3">Return Helper</a-select-option>
                      <a-select-option value="4">SZ Warehouse</a-select-option>
                  </a-select>
                </td>
                <td style="text-align:center;">|</td> -->
                <td colspan="2">
                  <a-select style="width: 100%" :value="other_field" @change="(e)=>handleChange(e,'other_field')">
                      <a-select-option value="sku">{{$t('inventory.search.sku')}}</a-select-option>
                      <a-select-option value="title">{{$t('inventory.search.product_name')}}</a-select-option>
                  </a-select>
                </td>
                <td><a-input placeholder="" :value="other_value" @change="(e)=>handleChange(e,'other_value')"/></td>
                <td>
                    <a-button class="ant-btn-danger" @click="mKPOrderIDBtn"  type="primary">{{$t('inventory.search.search')}}</a-button>
                </td>
              </tr>
            </table>
            <div style="">&nbsp;</div>
            <a-tabs default-active-key="0" @change="callback" :animated="false" size="large">
                <a-tab-pane key="0" :tab="$t('inventory.all')"></a-tab-pane>
                <a-tab-pane key="1" :tab="$t('inventory.tab_3')" force-render></a-tab-pane>
                <!-- <a-tab-pane key="1" tab="Gyroor" force-render></a-tab-pane> -->
                <a-tab-pane key="2" tab="DataHunt">
                  <!-- <a-button @click="addOrderBtn">CREATE MANUAL ORDER</a-button> -->
                </a-tab-pane>
                <a-tab-pane key="5" tab="Microsel" force-render></a-tab-pane>
                <!-- <a-tab-pane key="3" tab="Return Helper"></a-tab-pane> -->
                <!-- <a-tab-pane key="4" tab="SZ Warehouse"></a-tab-pane> -->
            </a-tabs>
            <a-table
              :columns="columns"
              :dataSource="dataSource" 
              :pagination="pagination" 
              :rowKey="(record, index) => index"  
              :loading="loading"
               @change="pageChange" 
               size="middle"
              >
              <img class="proimg" slot="main_img" slot-scope="text,data" :src='text' alt="error" @click="action_product(data.id)"/>
              <a-tag
                  slot="inventory_status" slot-scope="text"
                  :key="text"
                  :color="'geekblue'"
                >
                  {{$t(inventory_status_text(text))}}
                </a-tag>
                <a-tag
                  slot="condition_status" slot-scope="text"
                  :key="text"
                  :color="'geekblue'"
                >
                  {{$t(condition_status_text(text))}}
                </a-tag>
              <span slot="action" slot-scope="text, record">
                  <!-- <a>View</a>
                  <span style="padding: 0 5px;">|</span>
                  <a>List</a> -->
              </span>
              </a-table>
      </div>
      </a-spin>
  </div>
</template>

<script>
import {add,getList} from '@/network/inventory';
export default {
name: 'NodeprojectMedia',
components: {  },
directives: {  },
computed:{
  columns(){
    return [
        {
          title: this.$t('inventory.table.image'),
          key: "img",
          dataIndex: "img",
          scopedSlots: { customRender: "main_img" },
        },
        {
          title: this.$t('inventory.table.sku'),
          dataIndex: 'sku',
          key: 'sku',
        },
        {
          title: this.$t('inventory.table.product_name'),
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: this.$t('inventory.table.inventory_status'),
          dataIndex: 'inventory_status',
          key: 'inventory_status',
          scopedSlots: { customRender: 'inventory_status' },
        },
        {
          title: this.$t('inventory.table.condition'),
          dataIndex: 'condition',
          key: 'condition',
          scopedSlots: { customRender: 'condition_status' },
        },
        {
          title: this.$t('inventory.table.unit'),
          dataIndex: 'unit',
          key: 'unit',
        },
        {
          title: this.$t('inventory.table.action'),
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ];
  },
},
data() {
  return {
    loading:false,
    spinning:false,

    condition_0:true,
    condition_1:true,
    condition_2:true,
    condition_3:true,
    condition_4:true,
    condition_5:true,

    inventory_status_0:true,
    inventory_status_1:true,
    inventory_status_2:true,
    inventory_status_3:true,

    Warehouse:'0',
    other_field:'',
    other_value:'',

    parameter:{},

    pagination: { defaultPageSize: 15, current: 1, total: 0 },
    dataSource: [],
  };
},created(){
  this.getList(1);
},
mounted() {
  
},
methods: {
  condition_status_text(e){
    if(e == '1'){
          // return 'Brand New';
          // return 'Pre-Owned';
          return 'inventory.search.pre_owned';
        } else 
        if(e == '2'){
          // return 'UN - Unknown';
          // return 'Used';
          return 'inventory.search.used';
        } else 
        if(e == '3'){
          // return 'Brand New';
          // return 'Refurbushed 90P';
          return 'inventory.search.brand_new';
        } else 
        if(e == '4'){
          // return 'Refurbushed 80P';
        } else 
        if(e == '5'){
          // return 'Scrap';
        }
  },
  inventory_status_text(e) {
        if(e == '1'){
          // return 'In-Stock';
          return 'inventory.search.in_stock';
        } else
        if(e == '2'){
          // return 'In Inspection';
          return 'inventory.search.in_inspection';
        } else
        if(e == '3'){
          // return 'Pending to Receive';
          return 'inventory.search.pending_to_receive';
        }
    },
    callback(key) {
      console.log(key);
      this.Warehouse = key;
      this.parameter = {
        condition_0:this.condition_0,
        condition_1:this.condition_1,
        condition_2:this.condition_2,
        condition_3:this.condition_3,
        condition_4:this.condition_4,
        condition_5:this.condition_5,

        inventory_status_0:this.inventory_status_0,
        inventory_status_1:this.inventory_status_1,
        inventory_status_2:this.inventory_status_2,
        inventory_status_3:this.inventory_status_3,

        Warehouse:key,
        other_field:this.other_field,
        other_value:this.other_value,
      }
      this.getList(1);
    },
  mKPOrderIDBtn(){

    this.parameter = {
      condition_0:this.condition_0,
      condition_1:this.condition_1,
      condition_2:this.condition_2,
      condition_3:this.condition_3,
      condition_4:this.condition_4,
      condition_5:this.condition_5,

      inventory_status_0:this.inventory_status_0,
      inventory_status_1:this.inventory_status_1,
      inventory_status_2:this.inventory_status_2,
      inventory_status_3:this.inventory_status_3,

      Warehouse:this.Warehouse,
      other_field:this.other_field,
      other_value:this.other_value,
    }
    this.getList(1);
  },
  pageChange(pagination, filters, sorter) {
    console.log(pagination);
    const pager = { ...this.pagination };
    pager.current = pagination.current;
    this.pagination = pager;
    this.getList(pagination.current);
  },
  getList(page){
    this.loading = true;
    getList(page,this.parameter).then((res) => {
    if (res.code == 0) {
      this.$set(this.pagination, "total", res.total);
      this.dataSource = res.data;
      // this.dataSource =
      // page === 1
      //   ? res.data
      //   : this.dataSource.concat(res.data);
      // console.log(this.data)
      // let fornum = Math.ceil(res.total / 15);
      // if (page != fornum && fornum > 0) {
      //   console.log(page != 2);
      //   this.getList(page + 1);
      // }
      this.loading = false;
      return;
    }
  });
  },
  handleChange(e,k){
    console.log(e,k);
    if(k == 'condition_0'){
      if(this.condition_0){
          this.condition_0 = false;
          this.condition_1 = false;
          this.condition_2 = false;
          this.condition_3 = false;
          this.condition_4 = false;
          this.condition_5 = false;
      }else{
          this.condition_0 = true;
          this.condition_1 = true;
          this.condition_2 = true;
          this.condition_3 = true;
          this.condition_4 = true;
          this.condition_5 = true;
      }
    }
    if(k == 'condition_1'){
      if(this.condition_1){
          this.condition_0 = false;
          this.condition_1 = false;
      }else{
          if(this.condition_2 && this.condition_3 && this.condition_4 && this.condition_5){
            this.condition_0 = true;
            this.condition_1 = true;
            this.condition_2 = true;
            this.condition_3 = true;
            this.condition_4 = true;
            this.condition_5 = true;
          }else{
            this.condition_1 = true;
          }
      }
    }
    if(k == 'condition_2'){
      if(this.condition_2){
          this.condition_0 = false;
          this.condition_2 = false;
      }else{
          if(this.condition_1 && this.condition_3 && this.condition_4 && this.condition_5){
            this.condition_0 = true;
            this.condition_1 = true;
            this.condition_2 = true;
            this.condition_3 = true;
            this.condition_4 = true;
            this.condition_5 = true;
          }else{
            this.condition_2 = true;
          }
      }
    }
    if(k == 'condition_3'){
      if(this.condition_3){
          this.condition_0 = false;
          this.condition_3 = false;
      }else{
          if(this.condition_1 && this.condition_2 && this.condition_4 && this.condition_5){
            this.condition_0 = true;
            this.condition_1 = true;
            this.condition_2 = true;
            this.condition_3 = true;
            this.condition_4 = true;
            this.condition_5 = true;
          }else{
            this.condition_3 = true;
          }
      }
    }
    if(k == 'condition_4'){
      if(this.condition_4){
          this.condition_0 = false;
          this.condition_4 = false;
      }else{
          if(this.condition_1 && this.condition_2 && this.condition_3 && this.condition_5){
            this.condition_0 = true;
            this.condition_1 = true;
            this.condition_2 = true;
            this.condition_3 = true;
            this.condition_4 = true;
            this.condition_5 = true;
          }else{
            this.condition_4 = true;
          }
      }
    }
    if(k == 'condition_5'){
      if(this.condition_5){
          this.condition_0 = false;
          this.condition_5 = false;
      }else{
          if(this.condition_1 && this.condition_2 && this.condition_3 && this.condition_4){
            this.condition_0 = true;
            this.condition_1 = true;
            this.condition_2 = true;
            this.condition_3 = true;
            this.condition_4 = true;
            this.condition_5 = true;
          }else{
            this.condition_5 = true;
          }
      }
    }

    if(k == 'inventory_status_0'){
        if(this.inventory_status_0){
            this.inventory_status_0 = false;
            this.inventory_status_1 = false;
            this.inventory_status_2 = false;
            this.inventory_status_3 = false;
        }else{
            this.inventory_status_0 = true;
            this.inventory_status_1 = true;
            this.inventory_status_2 = true;
            this.inventory_status_3 = true;
        }
      }
      if(k == 'inventory_status_1'){
        if(this.inventory_status_1){
          this.inventory_status_0 = false;
          this.inventory_status_1 = false;
        }else{
          if(this.inventory_status_2 && this.inventory_status_3){
            this.inventory_status_0 = true;
            this.inventory_status_1 = true;
            this.inventory_status_2 = true;
            this.inventory_status_3 = true;
          }else{
            this.inventory_status_1 = true;
          }
        }
      }
      if(k == 'inventory_status_2'){
        if(this.inventory_status_2){
          this.inventory_status_0 = false;
          this.inventory_status_2 = false;
        }else{
          if(this.inventory_status_1 && this.inventory_status_3){
            this.inventory_status_0 = true;
            this.inventory_status_1 = true;
            this.inventory_status_2 = true;
            this.inventory_status_3 = true;
          }else{
            this.inventory_status_2 = true;
          }
        }
      }
      if(k == 'inventory_status_3'){
        if(this.inventory_status_3){
          this.inventory_status_0 = false;
          this.inventory_status_3 = false;
        }else{
          if(this.inventory_status_1 && this.inventory_status_2){
            this.inventory_status_0 = true;
            this.inventory_status_1 = true;
            this.inventory_status_2 = true;
            this.inventory_status_3 = true;
          }else{
            this.inventory_status_3 = true;
          }
        }
      }
      if(k == 'Warehouse'){
        this.Warehouse = e;
      }
      if(k == 'other_field'){
        this.other_field = e;
      }
      if(k == 'other_value'){
        this.other_value = e.target.value;
      }
    },
},
};
</script>

<style  scoped>
table tr td{
  padding: 8px;
}
.proimg {
    width: 50px;
    height: 50px;
    cursor: pointer;
}
</style>